import React from "react";
import "./FormInfo.css";
import ComponentUtils from "../ComponentUtils";
import FontAwesome from "react-fontawesome";
import { Button } from "reactstrap";
import FileDrop from "react-file-drop";
import FormInfo_Core from "./FormInfo_Core";
import SystemClass from "../../SystemClass";

class FormInfo extends FormInfo_Core {
  componentDidMount() {
    super.componentDidMount();
    window.addEventListener("resize", this._handleWindowResize);
    let formContainer = document.getElementsByClassName("FormInfo__container");
    formContainer = formContainer[formContainer.length - 1];
    const inputElms = formContainer.querySelectorAll(
      "input:not([disabled]):not([readonly]):not([type=hidden]),textarea:not([disabled]):not([readonly]):not([type=hidden])"
    );

    let formInputsArray = [...inputElms];

    for (const item of formInputsArray) {
      const index = formInputsArray.indexOf(item);
      let parent = item.parentNode;
      let foundedParent = null;

      while (true) {
        let classes = parent.classList.value;
        if (!classes.includes("FormInfo__fieldInfo")) {
          parent = parent.parentNode;
        } else {
          foundedParent = parent;
          break;
        }
      }
      if (foundedParent && foundedParent.style.display === "none") {
        formInputsArray.splice(index, 1);
      }
    }

    for (const item of formInputsArray) {
      const index = formInputsArray.indexOf(item);

      item.onfocus = () => {
        item.select();
      };

      item.onkeypress = (e) => {
        if (e.keyCode === 13) {
          formInputsArray[index + 1] && formInputsArray[index + 1].focus();
        }
      };

      item.onkeyup = (e) => {
        if (e.ctrlKey) {
          switch (e.keyCode) {
            // Arrow up
            case 38:
              formInputsArray[index - 1] && formInputsArray[index - 1].focus();
              break;
            // Arrow down
            case 40:
              formInputsArray[index + 1] && formInputsArray[index + 1].focus();
              break;
            default:
              break;
          }
        }
      };
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    window.removeEventListener("resize", this._handleWindowResize);
    if (this.props.setActiveComponent) {
      this.props.setActiveComponent("Transaction");
    }
  }

  _handleWindowResize = (event) => {
    this.forceUpdate();
  };

  //for update ui component such label
  updateUI() {
    SystemClass.DialogComponent && SystemClass.DialogComponent.forceUpdate();
  }

  handleChangeComponent = (newComp) => {
    this.props.setActiveComponent(newComp);
    this.forceUpdate();
    this.rebind();
  };

  //------------------------------------------------
  //region render
  //------------------------------------------------
  _elementGetFieldInfo(fieldInfo) {
    if (this.data.components[fieldInfo.fieldName])
      return this.data.components[fieldInfo.fieldName];
    let Tag = ComponentUtils.getComponentTag(fieldInfo);

    return (this.data.components[fieldInfo.fieldName] = (
      <Tag
        key={this.data.currentComponentTerm}
        fieldInfo={fieldInfo}
        setExternalData={(data) => this.setExternalData(data)}
        activeComponent={this.props.activeComponent}
        setActiveComponent={this.handleChangeComponent}
      />
    ));
  }

  _elementGetFormItem(fieldInfo, index) {
    const idColName = this.fieldInfo.idColName || "fieldName";

    const width_Total = this._getComponentTotalWidth(fieldInfo);
    const style = {
      minWidth: width_Total + "%",
      maxWidth: width_Total + "%",
      flex: !width_Total ? 1 : "",
      padding: ".5rem 0",
      display: !fieldInfo.visible && "none",
    };

    const styleLine = this.data.styleList.find(
      (item) => item.fieldInfo === fieldInfo
    );
    if (styleLine) {
      style.marginRight = styleLine.marginRight;
      style.marginLeft = styleLine.marginLeft;
      style.marginBottom = styleLine.marginBottom;
      style.marginTop = styleLine.marginTop;
    }

    return (
      <div
        className="FormInfo__fieldInfo"
        key={fieldInfo[idColName]}
        style={style}
      >
        {this._elementGetFieldInfo(fieldInfo)}
      </div>
    );
  }

  _elementGetAllField() {
    const fieldList = this.getFieldList();
    // .filter(i => i.visible)

    return (
      <div
        className={`FormInfo__container ${
          window.screen.width < 480 ? "mb-5" : ""
        }`}
      >
        {fieldList.map(this._elementGetFormItem.bind(this))}
      </div>
    );
  }

  _elementGetFormMenu() {
    if (!this.data.formMenu) {
      return <div style={{ height: "1rem" }} />;
    }

    // if (!this.data.formMenu.formMenu_Default_IsVisible && !this.data.showFormMenu) return
    // if (!this.data.showFormMenu) return

    const menuFieldList = this._getMenuFieldInfoList();
    const width_Total = 10;
    const style = {
      backgroundColor: this.data.formMenu.formMenu_BackColor,
    };

    return (
      <div
        className={[
          "FormInfo__menu",
          !this.data.showFormMenu && "FormInfo__menu--hide",
        ]
          .filter((c) => c)
          .join(" ")}
        style={style}
      >
        <div className={"FormInfo__menuIconContainer"}>
          <Button
            className={"Menu__icon FormInfo__menuIcon"}
            outline
            color="light"
            onClick={this._handleOnCloseMenuClick}
          >
            <FontAwesome className={""} name="times-circle" />
          </Button>
        </div>

        {menuFieldList.map(this._elementGetFormItem.bind(this))}
      </div>
    );
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="Form__container__error">
          <FontAwesome
            className="Form__container__error__icon"
            name="exclamation-triangle"
          />
          <span className="Form__container__error__text">
            خطایی روی داده است!
          </span>
          <Button
            onClick={this._handleReloadClick}
            outline
            className="Form__container__error__button"
          >
            <FontAwesome
              className="Form__container__error__button__icon"
              name="sync-alt"
            />
            بارگذاری مجدد
          </Button>
        </div>
      );
    }

    const formElement = (
      <div className={"FormInfo"} key={1}>
        <h5 className={"FormInfo__header"}>{this.fieldInfo.label}</h5>
        {this._elementGetFormMenu()}
        {this._elementGetAllField()}
        <div className={"FormInfo__fileDrop"} key={2} />
        {this.getHasBtnFileUpload() && (
          <input
            type="file"
            className={"FormInfo__fileDrop"}
            id={"FormInfo__fileUpload"}
          />
        )}
      </div>
    );

    const isUploadContainer = this._isUploadContainer();

    if (isUploadContainer) {
      return <FileDrop onDrop={this._handleDrop}>{formElement}</FileDrop>;
    } else {
      return formElement;
    }
  }

  //------------------------------------------------
  //endregion render
  //------------------------------------------------
}

export default FormInfo;
