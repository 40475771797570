import React from 'react';
import image from '../../../content/front/image-2.png';
import {p1_view1, p1_view3, p2_view3, p3_view3, t1_view1} from '../../../content/front/const/Text';

export default (props) => {
    return (
        <div>
            <div className="View_container">

                <div style={{flex: 1.2, marginTop: '2rem'}}>
                    <div className="View_textContainer">

                        <p className="View_p" style={{textAlign: 'center'}}>
                            {p1_view3}
                            <b>
                                {p2_view3}
                            </b>
                            {p3_view3}
                        </p>

                    </div>
                </div>

                <div className="View_imgContainer" style={{order: -1}}>
                    <img className="View_img" src={image}/>
                </div>

            </div>
        </div>
    )
};

