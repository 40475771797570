import React, {Component} from 'react';
import './ChartFieldInfo.css';
import Utils from "../../Utils";
import * as ReactDOM from "react-dom";
import Tooltip from "reactstrap/es/Tooltip";
import {UncontrolledTooltip} from "reactstrap";
import TextFieldInfo from "../TextFieldInfo/TextFieldInfo";
import defaultImage from '../../content/divider.png';
import Button from "reactstrap/es/Button";
import LabelPosition from "../../class/enums/LabelPosition";
import ButtonActionTypes from "../../class/enums/ButtonActionTypes";
import SystemClass from "../../SystemClass";
import WebService from "../../WebService";
import FieldType from "../../class/enums/FieldType";
import FontAwesome from 'react-fontawesome'
import FileUtils from "../../file/FileUtils";
import ChartFieldInfo_Core from "./ChartFieldInfo_Core";

import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import ChartType from "../../class/enums/ChartType";

class ChartFieldInfo extends ChartFieldInfo_Core {

    //------------------------------------------------
    //region component public method
    //------------------------------------------------

    componentDidMount() {
        this.data.node = ReactDOM.findDOMNode(this) //get node of button
        const chartType = ChartType[this.fieldInfo.chartType]
        if (!chartType) {

            return SystemClass.showErrorMsg("تایپ چارت تعیین نشده است " + this.fieldInfo.fieldName)
        }

        const chart = am4core.create(this.data.node, chartType);

        this.dataSource = this._dataGetDataSource()
        chart.data = this.dataSource.dataArray

        const text = this.fieldInfo.chart_TextColName_1 || this.fieldInfo.chart_DateColName_1
        const value = this.fieldInfo.chart_ValueColName_1

        chart.data.sort(function (a, b) {
            if (a[text] > b[text]) {
                return 1;
            } else if (a[text] < b[text]) {
                return -1
            } else {
                return 0;
            }
        })

        chart.cursor = new am4charts.XYCursor();
        chart.scrollbarX = new am4core.Scrollbar();
        chart.rtl = true;

        chart.colors.step = 2;

        if (this.fieldInfo.chart_Leged_ShowLegend) {
            chart.legend = new am4charts.Legend();
        }

        //chart_Leged_ShowLegend

        if (this.fieldInfo.chartType == 'PieChart') {
            chart.innerRadius = am4core.percent(this.fieldInfo.chart_InnerRadius_Percent || 25);
            const pieSeries = chart.series.push(new am4charts.PieSeries());
            pieSeries.dataFields.value = value
            pieSeries.dataFields.category = text
        } else if (this.fieldInfo.chartType == 'XyChart_Category_Inversed' || this.fieldInfo.chartType == 'XyChart_Category') {

            // Create axes
            const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = text
            //categoryAxis.numberFormatter.numberFormat = "#";

            categoryAxis.renderer.inversed = chartType == ChartType.XyChart_Category_Inversed;

            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.cellStartLocation = 0.1;
            categoryAxis.renderer.cellEndLocation = 0.9;

            const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
            valueAxis.renderer.opposite = true;

            // Create series
            const createSeries = (index) => {

                const field = this.fieldInfo['chart_ValueColName_' + index]
                const name = this.fieldInfo['chart_ValueColName_' + index + "_Title"]

                const series = chart.series.push(new am4charts.ColumnSeries());
                series.dataFields.valueX = field;
                series.dataFields.categoryY = text;
                series.name = name;
                series.columns.template.tooltipText = "{name}: [bold]{valueX}[/]";
                series.columns.template.height = am4core.percent(100);
                series.sequencedInterpolation = true;

                const valueLabel = series.bullets.push(new am4charts.LabelBullet());
                valueLabel.label.text = "{valueX}";
                valueLabel.label.horizontalCenter = "left";
                valueLabel.label.dx = 10;
                valueLabel.label.hideOversized = false;
                valueLabel.label.truncate = false;

                const categoryLabel = series.bullets.push(new am4charts.LabelBullet());
                categoryLabel.label.text = "{name}";
                categoryLabel.label.horizontalCenter = "right";
                categoryLabel.label.dx = -10;
                categoryLabel.label.fill = am4core.color("#fff");
                categoryLabel.label.hideOversized = false;
                categoryLabel.label.truncate = false;
            }

            for (let i = 1; i <= 5; i++) {
                if (this.fieldInfo['chart_ValueColName_' + i]) {
                    createSeries(i);
                }
            }

        } else {

            const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = this.fieldInfo.chart_DateColName_1
            // categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.gridCount = chart.data.length;
            categoryAxis.autoGridCount = false;
            categoryAxis.groupData = false;
            categoryAxis.tooltip.disabled = true;


            const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.renderer.minWidth = 16;
            valueAxis.tooltip.disabled = true;

            for (let i = 1; i <= 5; i++) {

                if (!this.fieldInfo['chart_ValueColName_' + i]) break



                // if (chart.yAxes.indexOf(valueAxis) != 0) {
                //     valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
                // }

                const series = chart.series.push(new am4charts.LineSeries());
                series.dataFields.dateX = this.fieldInfo.chart_DateColName_1
                series.dataFields.categoryX = this.fieldInfo.chart_DateColName_1
                series.dataFields.valueY = this.fieldInfo['chart_ValueColName_' + i]

                // series.yAxis = valueAxis;

                const name = this.fieldInfo['chart_ValueColName_' + i + "_Title"]
                series.name = name;
                series.tooltipText = "{valueY}";
                series.bullets.push(new am4charts.CircleBullet());
                //series.stroke = chart.colors.getIndex(13);
                //series.fill = series.stroke;
                series.strokeWidth = 2;
                series.snapTooltip = true;

                const bullet = series.bullets.push(new am4charts.CircleBullet());

                const interfaceColors = new am4core.InterfaceColorSet();

                bullet.circle.stroke = interfaceColors.getFor("background");
                bullet.circle.strokeWidth = 2;

                // valueAxis.renderer.line.strokeOpacity = 1;
                // valueAxis.renderer.line.strokeWidth = 2;
                // valueAxis.renderer.line.stroke = series.stroke;
                // valueAxis.renderer.labels.template.fill = series.stroke;
                // valueAxis.renderer.opposite = i % 2 == 0;

            }

        }

        this.forceUpdate()
    }

    componentWillUnmount() {
        this.data.node = null
    }

    //------------------------------------------------
    //endregion component public method
    //------------------------------------------------

    //------------------------------------------------
    //region render
    //------------------------------------------------

    render() {
        const labelPositionClass = this.fieldInfo.labelPosition === LabelPosition.LabelOnTop && "TextFieldInfo--column"
        const sliceWidths = this._getLabelWidthStyles()

        const styleLabel = {
            maxWidth: sliceWidths.slice1,
            minWidth: sliceWidths.slice1,
        }
        const styleInput = {
            maxWidth: sliceWidths.slice2,
            minWidth: sliceWidths.slice2,
        }
        const styleLabelAfter = {
            maxWidth: sliceWidths.slice3,
            minWidth: sliceWidths.slice3,
        }

        const icon = this.fieldInfo.button_IconName
        const image = this.fieldInfo.button_ImageName
        const hideText = !this.fieldInfo.button_ShowText && !!icon

        const iconButton = icon && !image && hideText

        const hideLabel = this.fieldInfo.label_HideLabel

        const button_ShowNumber_FieldName = this.fieldInfo.button_ShowNumber_FieldName
        const number = this.fieldInfo._row && this.fieldInfo._row[button_ShowNumber_FieldName]

        //number exit and not Zero
        const numberElement = (!number) ? '' : <span className={'ButtonFieldInfo__number'}>{number}</span>

        // if (this.fieldInfo._row && this.fieldInfo._row.fieldName === 'fileNameAndPath') console.log(this.fieldInfo)

        if (this.fieldInfo.fontColor) {
            styleInput.color = this.fieldInfo.fontColor
        }

        const style = {minHeight: '24rem'}
        const chartType = ChartType[this.fieldInfo.chartType]
        if (chartType == ChartType.XyChart_Category_Inversed || chartType == ChartType.XyChart_Category) {
            style.minHeight = '1000px'
        }

        if (this.fieldInfo.height_Total) {
            style.minHeight = this.fieldInfo.height_Total + 'px'
        }

        return (

            <div style={style} className={'ChartFieldInfo'}></div>

        );
    }

    //------------------------------------------------
    //endregion render
    //------------------------------------------------
}

export default ChartFieldInfo;
