import Enum_Base from "./Enum_Base";

/** Enum Class HorizontalAlign */
class HorizontalAlign extends Enum_Base {
    /** Enum of HorizontalAlign
     * @typedef {string} HorizontalAlign */
    static left = "Left"
    static right = "Right"
    static center = "Center"
}

export default HorizontalAlign;