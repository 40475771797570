

import 'bootstrap/dist/css/bootstrap.min.css';
import './content/icon/solid.min.css';
import './content/icon/fontawesome.min.css';
// import './content/icon/all.min.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import './bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.min.css';

import './App.css';



class CSSImporter {

    static init(){

    }
}

export default CSSImporter