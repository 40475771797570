import React from 'react';
import CardContainer from "../Components/CardContainer";

import data from '../../../content/front/const/Emkanat'
import {t1_view2} from "../../../content/front/const/Text";

export default (props) => {
    return (
        <div>
            <div>

                <h2 style={{
                    textAlign: 'center',
                    padding: '2rem'
                }}>
                    {t1_view2}
                </h2>

            </div>
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                <CardContainer data={[data[0], data[1], data[2], data[3]]}/>
                <CardContainer data={[data[4], data[5], data[6], data[7]]}/>
                <CardContainer data={[data[8], data[9], data[10], data[11]]}/>
            </div>
        </div>
    )
};

