import React, {Component} from 'react';
import {gantt} from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import './Gantt.css';
import SystemClass from "../../SystemClass";
import TextFieldInfo from "../TextFieldInfo/TextFieldInfo";
import Utils from "../../Utils";

export default class GanttFieldInfo extends TextFieldInfo {

    // instance of gantt.dataProcessor
    dataProcessor = null;

    initialize() {
        this.initZoom();

        const zoom = this.zoom;
        const zoomToFit = this.zoomToFit

        // a single day-scale
        gantt.config.scales = [
            {unit: "day", step: 1, format: "%j, %D"}
        ];

        gantt.config.rtl = true;
        // gantt.config.fit_tasks = true;

        const ganttModules = gantt

        function addClass(node, className) {
            node.className += " " + className;
        }

        function removeClass(node, className) {
            node.className = node.className.replace(new RegExp(" *" + className.replace(/\-/g, "\\-"), "g"), "");
        }

        function getButton(name) {
            return document.querySelector(".gantt-controls [data-action='" + name + "']");
        }

        function highlightButton(name) {
            addClass(getButton(name), "menu-item-active");
        }

        function unhighlightButton(name) {
            removeClass(getButton(name), "menu-item-active");
        }

        function disableButton(name) {
            addClass(getButton(name), "menu-item-disabled");
        }

        function enableButton(name) {
            removeClass(getButton(name), "menu-item-disabled");
        }

        function refreshZoomBtns() {
            if (zoom.canZoomIn()) {
                enableButton("zoomIn");
            } else {
                disableButton("zoomIn");
            }
            if (zoom.canZoomOut()) {
                enableButton("zoomOut");
            } else {
                disableButton("zoomOut");
            }
        }

        function toggleZoomToFitBtn() {
            if (zoomToFit.isEnabled()) {
                highlightButton("zoomToFit");
            } else {
                unhighlightButton("zoomToFit");
            }
        }

        this.menu = {
            // undo: function(){
            //     gantt.undo();
            //     refreshUndoBtns();
            // },
            // redo: function(){
            //     gantt.redo();
            //     refreshUndoBtns();
            // },
            zoomIn: function () {
                //ganttModules.zoomToFit.disable();
                zoom.zoomIn();
                refreshZoomBtns();
                toggleZoomToFitBtn()
            },
            zoomOut: function () {
                //ganttModules.zoomToFit.disable();
                zoom.zoomOut();
                refreshZoomBtns();
                toggleZoomToFitBtn()
            },
            zoomToFit: function () {
                zoom.deactivate();
                zoomToFit.toggle();
                toggleZoomToFitBtn();
                refreshZoomBtns();
            },
            fullscreen: function () {
                gantt.expand();
            },
            collapseAll: function () {
                gantt.eachTask(function (task) {
                    task.$open = false;
                });
                gantt.render();

            },
            expandAll: function () {
                gantt.eachTask(function (task) {
                    task.$open = true;
                });
                gantt.render();
            },
            toggleAutoScheduling: function () {
                gantt.config.auto_scheduling = !gantt.config.auto_scheduling;
                if (gantt.config.auto_scheduling) {
                    gantt.autoSchedule();
                    highlightButton("toggleAutoScheduling");
                } else {
                    unhighlightButton("toggleAutoScheduling")
                }
            },
            toggleCriticalPath: function () {
                gantt.config.highlight_critical_path = !gantt.config.highlight_critical_path;
                if (gantt.config.highlight_critical_path) {
                    highlightButton("toggleCriticalPath");
                } else {
                    unhighlightButton("toggleCriticalPath")
                }
                gantt.render();
            },
            toPDF: function () {
                gantt.exportToPDF();
            },
            toPNG: function () {
                gantt.exportToPNG();
            },
            toExcel: function () {
                gantt.exportToExcel();
            },
            toMSProject: function () {
                gantt.exportToMSProject();
            }
        };

        gantt.plugins({
            fullscreen: true,
            zoom: true,
            tooltip: true
        });


        gantt.config.scales = [
            {
                unit: "month", step: 1, format: function (date) {

                    return "<strong>" + Utils.DateUtil(date).month + "</strong>";
                }
            },
            {
                unit: "day", step: 1, format: function (date) {
                    return "<strong>" + Utils.DateUtil(date).day + " " + Utils.DateUtil(date).week + "</strong>";
                }
            }
        ];

        gantt.config.columns = [
            {name: "text", label: "نام وظیفه", width: "*", tree: true},
            {name: "start_date_Shamsi", label: "زمان شروع", align: "center"},
            {name: "duration", label: "مدت", align: "center"},
        ];

        gantt.config.min_column_width = 64;

        gantt.config.layout = {
            css: "gantt_container",
            rows: [
                {
                    cols: [
                        {view: "scrollbar", id: "scrollVer"},
                        {view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer"},
                        {resizer: true, width: 1},
                        {view: "grid", scrollX: "scrollHor", scrollY: "scrollVer"}
                    ]
                },
                {view: "scrollbar", id: "scrollHor", height: 20}
            ]
        };

        gantt.templates.task_text = function (start, end, task) {
            return task.text
            // return task.start_date_Shamsi + "-" + task.end_date_Shamsi + " - " + task.text + " " + progress
        };

        gantt.templates.tooltip_text = function (start, end, task) {
            let text = "";
            text += "نام: " + task.text + "<br/>"
            text += "تاریخ شروع: " + task.start_date_Shamsi + "<br/>"
            text += "تاریخ اتمام: " + task.end_date_Shamsi + "<br/>"
            text += " درصد پیشرفت: " + parseInt(task.progress * 100) + "%" + "<br/>"
            return text
        };

        // gantt.attachEvent("onTaskClick", function(id,e){
        //     //any custom logic here
        //     return false;
        // });

        gantt.attachEvent("onBeforeLightbox", function(id) {
            return false;
        });
    }

    initZoom() {
        gantt.ext.zoom.init({
            levels: [
                {
                    name: 'Hours',
                    scale_height: 60,
                    min_column_width: 30,
                    scales: [
                        {unit: 'day', step: 1, format: '%d %M'},
                        {unit: 'hour', step: 1, format: '%H'}
                    ]
                },
                {
                    name: 'Days',
                    scale_height: 60,
                    min_column_width: 70,
                    scales: [
                        {unit: 'week', step: 1, format: 'Week #%W'},
                        {unit: 'day', step: 1, format: '%d %M'}
                    ]
                },
                {
                    name: 'Months',
                    scale_height: 60,
                    min_column_width: 70,
                    scales: [
                        {unit: "month", step: 1, format: '%F'},
                        {unit: 'week', step: 1, format: '#%W'}
                    ]
                }
            ]
        });

        this.zoom = (function (gantt) {

            gantt.ext.zoom.init({
                levels: [
                    {
                        name: "hours",
                        scales: [
                            {
                                unit: "day", step: 1, format: function (date) {
                                    return "<strong>" + Utils.DateUtil(date).day + " " + Utils.DateUtil(date).month + "</strong>";
                                }
                            },
                            {
                                unit: "hour", step: 1, format: function (date) {
                                    return "<strong>" + Utils.DateUtil(date).time + "</strong>";
                                }
                            },
                        ],
                        round_dnd_dates: true,
                        min_column_width: 30,
                        scale_height: 60
                    },
                    {
                        name: "days",
                        scales: [
                            {
                                unit: "month", step: 1, format: function (date) {
                                    return "<strong>" + Utils.DateUtil(date).month + "</strong>";
                                }
                            },
                            {
                                unit: "day", step: 1, format: function (date) {
                                    return "<strong>" + Utils.DateUtil(date).day + "</strong>";
                                }
                            },
                        ],
                        round_dnd_dates: true,
                        min_column_width: 60,
                        scale_height: 60
                    },
                    {
                        name: "weeks",
                        scales: [
                            {
                                unit: "year", step: 1, format: function (date) {
                                    return "<strong>" + Utils.DateUtil(date).year + "</strong>";
                                }
                            },
                            {
                                unit: "month", step: 1, format: function (date) {
                                    return "<strong>" + Utils.DateUtil(date).month + "</strong>";
                                }
                            },
                            {
                                unit: "week", step: 1, format: function (date) {
                                    return "<strong>" + Utils.DateUtil(date).day + "</strong>";
                                }
                            },
                        ],
                        round_dnd_dates: false,
                        min_column_width: 60,
                        scale_height: 60
                    },
                    {
                        name: "months",
                        scales: [
                            {
                                unit: "year", step: 1, format: function (date) {
                                    return "<strong>" + Utils.DateUtil(date).year + "</strong>";
                                }
                            },
                            {
                                unit: "month", step: 1, format: function (date) {
                                    return "<strong>" + Utils.DateUtil(date).month + "</strong>";
                                }
                            },

                        ],
                        round_dnd_dates: false,
                        min_column_width: 50,
                        scale_height: 60
                    },
                    {
                        name: "quarters",
                        scales: [
                            {
                                unit: "year", step: 1, format: function (date) {
                                    return "<strong>" + Utils.DateUtil(date).year + "</strong>";
                                }
                            },
                            {
                                unit: "quarter", step: 1, format: function quarterLabel(date) {
                                    var month = date.getMonth();
                                    var q_num;

                                    if (month >= 9) {
                                        q_num = 4;
                                    } else if (month >= 6) {
                                        q_num = 3;
                                    } else if (month >= 3) {
                                        q_num = 2;
                                    } else {
                                        q_num = 1;
                                    }

                                    return "Q" + q_num;
                                }
                            },
                            {
                                unit: "month", step: 1, format: function (date) {
                                    return "<strong>" + Utils.DateUtil(date).month + "</strong>";
                                }
                            },
                        ],
                        round_dnd_dates: false,
                        min_column_width: 50,
                        scale_height: 60
                    },
                    {
                        name: "years",
                        scales: [
                            {
                                unit: "year", step: 1, format: function (date) {
                                    return "<strong>" + Utils.DateUtil(date).year + "</strong>";
                                }
                            },
                        ],
                        round_dnd_dates: false,
                        min_column_width: 50,
                        scale_height: 60
                    }
                ]

            });

            var isActive = true;

            return {
                deactivate: function () {
                    isActive = false;
                },
                setZoom: function (level) {
                    isActive = true;
                    gantt.ext.zoom.setLevel(level);
                },
                zoomOut: function () {
                    isActive = true;
                    gantt.ext.zoom.zoomOut();
                    gantt.render();
                },
                zoomIn: function () {
                    isActive = true;
                    gantt.ext.zoom.zoomIn();
                    gantt.render();
                },
                canZoomOut: function () {
                    var level = gantt.ext.zoom.getCurrentLevel();

                    return !isActive || !(level > 4);
                },
                canZoomIn: function () {
                    var level = gantt.ext.zoom.getCurrentLevel();
                    return !isActive || !(level === 0);
                }
            };
        })(gantt);

        this.zoomToFit = (function (gantt) {
            var cachedSettings = {};

            function saveConfig() {
                var config = gantt.config;
                cachedSettings = {};
                cachedSettings.scales = config.scales;
                cachedSettings.template = gantt.templates.date_scale;
                cachedSettings.start_date = config.start_date;
                cachedSettings.end_date = config.end_date;
            }

            function restoreConfig() {
                applyConfig(cachedSettings);
            }

            function applyConfig(config, dates) {
                if (config.scales[0].date) {
                    gantt.templates.date_scale = null;
                } else {
                    gantt.templates.date_scale = config.scales[0].template;
                }

                gantt.config.scales = config.scales;

                if (dates && dates.start_date && dates.start_date) {
                    gantt.config.start_date = gantt.date.add(dates.start_date, -1, config.scales[0].subscale_unit);
                    gantt.config.end_date = gantt.date.add(gantt.date[config.scales[0].subscale_unit + "_start"](dates.end_date), 2, config.scales[0].subscale_unit);
                } else {
                    gantt.config.start_date = gantt.config.end_date = null;
                }
            }


            function zoomToFit() {
                var project = gantt.getSubtaskDates(),
                    areaWidth = gantt.$task.offsetWidth;

                for (var i = 0; i < scaleConfigs.length; i++) {
                    var columnCount = getUnitsBetween(project.start_date, project.end_date, scaleConfigs[i].scales[0].subscale_unit, scaleConfigs[i].scales[0].step);
                    if ((columnCount + 2) * gantt.config.min_column_width <= areaWidth) {
                        break;
                    }
                }

                if (i == scaleConfigs.length) {
                    i--;
                }

                applyConfig(scaleConfigs[i], project);
                gantt.render();
            }

            // get number of columns in timeline
            function getUnitsBetween(from, to, unit, step) {
                var start = new Date(from),
                    end = new Date(to);
                var units = 0;
                while (start.valueOf() < end.valueOf()) {
                    units++;
                    start = gantt.date.add(start, step, unit);
                }
                return units;
            }

            //Setting available scales
            var scaleConfigs = [
                // minutes
                {
                    scales: [
                        {subscale_unit: "minute", unit: "hour", step: 1, format: "%H"},
                        {unit: "minute", step: 1, format: "%H:%i"}
                    ]
                },
                // hours
                {
                    scales: [
                        {subscale_unit: "hour", unit: "day", step: 1, format: "%j %M"},
                        {unit: "hour", step: 1, format: "%H:%i"}

                    ]
                },
                // days
                {
                    scales: [
                        {subscale_unit: "day", unit: "month", step: 1, format: "%F"},
                        {unit: "day", step: 1, format: "%j"}
                    ]
                },
                // weeks
                {
                    scales: [
                        {subscale_unit: "week", unit: "month", step: 1, date: "%F"},
                        {
                            unit: "week", step: 1, template: function (date) {
                                var dateToStr = gantt.date.date_to_str("%d %M");
                                var endDate = gantt.date.add(gantt.date.add(date, 1, "week"), -1, "day");
                                return dateToStr(date) + " - " + dateToStr(endDate);
                            }
                        }
                    ]
                },
                // months
                {
                    scales: [
                        {subscale_unit: "month", unit: "year", step: 1, format: "%Y"},
                        {unit: "month", step: 1, format: "%M"}
                    ]
                },
                // quarters
                {
                    scales: [
                        {subscale_unit: "month", unit: "year", step: 3, format: "%Y"},
                        {
                            unit: "month", step: 3, template: function (date) {
                                var dateToStr = gantt.date.date_to_str("%M");
                                var endDate = gantt.date.add(gantt.date.add(date, 3, "month"), -1, "day");
                                return dateToStr(date) + " - " + dateToStr(endDate);
                            }
                        }
                    ]
                },
                // years
                {
                    scales: [
                        {subscale_unit: "year", unit: "year", step: 1, date: "%Y"},
                        {
                            unit: "year", step: 5, template: function (date) {
                                var dateToStr = gantt.date.date_to_str("%Y");
                                var endDate = gantt.date.add(gantt.date.add(date, 5, "year"), -1, "day");
                                return dateToStr(date) + " - " + dateToStr(endDate);
                            }
                        }
                    ]
                },
                // decades
                {
                    scales: [
                        {
                            subscale_unit: "year", unit: "year", step: 10, template: function (date) {
                                var dateToStr = gantt.date.date_to_str("%Y");
                                var endDate = gantt.date.add(gantt.date.add(date, 10, "year"), -1, "day");
                                return dateToStr(date) + " - " + dateToStr(endDate);
                            }
                        },
                        {
                            unit: "year", step: 100, template: function (date) {
                                var dateToStr = gantt.date.date_to_str("%Y");
                                var endDate = gantt.date.add(gantt.date.add(date, 100, "year"), -1, "day");
                                return dateToStr(date) + " - " + dateToStr(endDate);
                            }
                        }
                    ]
                }
            ];

            var enabled = false;
            return {
                enable: function () {
                    if (!enabled) {
                        enabled = true;
                        saveConfig();
                        zoomToFit();
                        gantt.render();
                    }
                },
                isEnabled: function () {
                    return enabled;
                },
                toggle: function () {
                    if (this.isEnabled()) {
                        this.disable();
                    } else {
                        this.enable();
                    }
                },
                disable: function () {
                    if (enabled) {
                        enabled = false;
                        restoreConfig();
                        gantt.render();
                    }
                }
            };

        })(gantt);
    }

    setZoom(value) {
        // gantt.ext.zoom.setLevel(value);
    }

    initGanttDataProcessor() {
        /**
         * type: "task"|"link"
         * action: "create"|"update"|"delete"
         * item: data object object
         */
        const onDataUpdated = this.props.onDataUpdated;
        this.dataProcessor = gantt.createDataProcessor((type, action, item, id) => {
            return new Promise((resolve, reject) => {
                if (onDataUpdated) {
                    onDataUpdated(type, action, item, id);
                }

                // if onDataUpdated changes returns a permanent id of the created item, you can return it from here so dhtmlxGantt could apply it
                // resolve({id: databaseId});
                return resolve();
            });
        });
    }

    shouldComponentUpdate(nextProps) {
        return this.props.zoom !== nextProps.zoom;
    }

    componentDidMount() {
        gantt.config.xml_date = "%Y-%m-%d %H:%i";

        const ds1 = this.fieldInfo.getDataSource(this.fieldInfo.dataSourceName)
        const ds2 = this.fieldInfo.getDataSource(this.fieldInfo.dataSourceName_2)

        if (!ds1) {
            return SystemClass.showErrorMsg('دیتاسورس اول پیدا نشد!')
        }

        if (!ds2) {
            return SystemClass.showErrorMsg('دیتاسورس دوم پیدا نشد!')
        }

        const tasks = {};

        tasks.tasks = ds1.dataArray
        tasks.links = ds2.dataArray

        gantt.init(this.ganttContainer);
        this.initGanttDataProcessor();
        gantt.parse(tasks);

        this.ganttContainer.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.style.margin = "0"
    }

    componentWillUnmount() {
        if (this.dataProcessor) {
            this.dataProcessor.destructor();
            this.dataProcessor = null;
        }
    }

    render() {
        this.setZoom('days');
        return (
            <div>

                <div className="header gantt-demo-header">
                    <ul className="gantt-controls">
                        <li className="gantt-menu-item" onClick={this.menu.collapseAll}><a
                            data-action="collapseAll"><img
                            src="demo/imgs/ic_collapse_all_24.png"/> بستن همه </a></li>
                        <li className="gantt-menu-item gantt-menu-item-last" onClick={this.menu.expandAll}><a
                            data-action="expandAll"><img
                            src="demo/imgs/ic_expand_all_24.png"/> بازکردن همه </a></li>

                        {/*<li className="gantt-menu-item" onClick={this.menu.toggleAutoScheduling}><a data-action="toggleAutoScheduling" className=""><img*/}
                        {/*    src="demo/imgs/ic_auto_scheduling_24.png"/> زمان گذاری خودکار </a></li>*/}
                        <li className="gantt-menu-item" onClick={this.menu.toggleCriticalPath}><a
                            data-action="toggleCriticalPath" className=""><img
                            src="demo/imgs/ic_critical_path_24.png"/> مسیر بحرانی </a>
                        </li>

                        {/*<li className="gantt-menu-item gantt-menu-item-right"><a data-action="fullscreen"><img*/}
                        {/*    src="demo/imgs/ic_fullscreen_24.png"/>Fullscreen</a></li>*/}

                        <li style={{display: 'none'}}
                            className="gantt-menu-item gantt-menu-item-right gantt-menu-item-last"
                            onClick={this.menu.zoomToFit}><a
                            data-action="zoomToFit" className=""><img src="demo/imgs/ic_zoom_to_fit_24.png"/> زوم کامل
                        </a>
                        </li>
                        <li className="gantt-menu-item gantt-menu-item-right" onClick={this.menu.zoomIn}><a
                            data-action="zoomOut" className=""><img
                            src="demo/imgs/ic_zoom_out.png"/> زوم + </a></li>
                        <li className="gantt-menu-item gantt-menu-item-right" onClick={this.menu.zoomOut}><a
                            data-action="zoomIn" className=""><img
                            src="demo/imgs/ic_zoom_in.png"/> زوم - </a></li>
                        {/*<li className="gantt-menu-item gantt-menu-item-right gantt-menu-item-last"><a><img*/}
                        {/*    src="demo/imgs/ic_export_24.png"/>Export</a>*/}
                        {/*    <ul className="gantt-controls">*/}
                        {/*        <li className="gantt-menu-item"><a data-action="toPDF"><img*/}
                        {/*            src="demo/imgs/ic_file_24.png"/>PDF</a></li>*/}
                        {/*        <li className="gantt-menu-item"><a data-action="toPNG"><img*/}
                        {/*            src="demo/imgs/ic_file_24.png"/>PNG</a></li>*/}
                        {/*        <li className="gantt-menu-item"><a data-action="toExcel"><img*/}
                        {/*            src="demo/imgs/ic_file_24.png"/>Excel</a></li>*/}
                        {/*        <li className="gantt-menu-item"><a data-action="toMSProject"><img*/}
                        {/*            src="demo/imgs/ic_file_24.png"/>MS Project</a></li>*/}
                        {/*    </ul>*/}
                        {/*</li>*/}
                    </ul>
                </div>

                <div
                    ref={(input) => {
                        this.ganttContainer = input
                    }}
                    style={{width: '100%', height: '100%'}}
                ></div>
            </div>
        );
    }
}
