import React from 'react';
import image from '../../../content/front/image-3.png';
import btn_bazaar from '../../../content/front/btn-bazar.png';
import btn_sibapp from '../../../content/front/btn-sibapp.png';
import {p1_view1, p1_view4, p2_view4, t1_view1, t1_view4} from '../../../content/front/const/Text';

export default (props) => {
    return (
        <div>
            <div className="View_container">

                <div style={{flex: 1.2, marginTop: '2rem'}}>
                    <div className="View_textContainer">
                        <h2> {t1_view4} </h2>
                        <b><h4> {p1_view4} </h4></b>

                        <p className="View_p">
                            {p2_view4}
                        </p>

                        <div>
                            <img className="View_imgButton" src={btn_bazaar}/>
                            <img style={{marginRight: '.25rem'}} className="View_imgButton" src={btn_sibapp}/>
                        </div>

                    </div>
                </div>

                <div className="View_imgContainer">
                    <img className="View_img" src={image}/>
                </div>

            </div>
        </div>
    )
};

