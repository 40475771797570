import React, {Component, Fragment, PureComponent} from 'react';
import BaseComponent from "./BaseComponent";
import * as ReactDOM from "react-dom";
import SystemClass from "../SystemClass";

class LoadingContainer extends BaseComponent {
    constructor(props) {
        super(props)
        this.state = {}
        this.data = {}
    }


    componentDidMount() {
        this.data.node = ReactDOM.findDOMNode(this)

    }

    componentWillUnmount() {
    }

    _handleEvents = (event) => {
        if (SystemClass.loading) {
            event && event.stopPropagation()
            event && event.preventDefault()
        }
    }

    render() {
        return (
            <div onKeyDown={this._handleEvents} onTouchStart={this._handleEvents} onKeyPress={this._handleEvents}
                 id="maskDisable" className={"maskDisable"}/>
        )
    }

}

export default LoadingContainer;