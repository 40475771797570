import WebService from "../WebService";

class FileUtils {
  static GetFileUrl(name) {
    return WebService.URL.webService_baseAddress + name;
  }

  static DownloadFile(url, name) {
    let a = document.createElement("a");
    a.href = url;
    a.target = "blank";
    a.download = name || url.split("/").pop() || "file";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  static GetFile(callback) {
    // Trigger a file open dialog and read local file, then read and log the file contents
    // let element = document.createElement("div");
    // element.innerHTML = '<input type="file" />';
    // let fileInput = element.firstChild;

    const fileInput = document.getElementById("FormInfo__fileUpload");

    fileInput.addEventListener("change", function(event) {
      console.log("Change happend.............");
      callback(fileInput.files);
    });

    fileInput.click();
  }

  static IsImage(fileName) {
    if (!fileName) return;

    const ext = fileName.split(".").pop();
    switch (ext.toLowerCase()) {
      case "jpeg":
      case "jpg":
      case "png":
      case "apng":
      case "gif":
      case "svg":
      case "bmp":
      case "webp":
      case "ico":
      case "heic":
        return true;
    }
  }
}

export default FileUtils;
