import React from "react";
import "./Home.css";
import NavHeader from "./Components/NavHeader";
import MyFooter from "./Components/MyFooter";
import FirstView from "./views/FirstView";
import SecondView from "./views/SecondView";
import ThirdView from "./views/ThirdView";
import FourthView from "./views/FourthView";
import FifthView from "./views/FifthView";
import SystemClass from "../../SystemClass";

export default () => {
  //   window.location.assign("/auth/login");
  SystemClass.pushLink("/auth/login");
  console.log("This is home component");
  return (
    <>
      <NavHeader />

      <div className={"scroll__container"}>
        <div style={{ padding: "0 1.5rem" }}>
          <FirstView height={550} />
          <div />
          <SecondView />
          <div />
          <ThirdView />
          <div />
          {/*<ChartView/>*/}
          {/*<Space/>*/}
          <FourthView />
          <div />
          <FifthView />
          <div style={{ padding: "0 0 2rem 0" }} />
        </div>

        <MyFooter />
      </div>
    </>
  );
};
