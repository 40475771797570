import React from 'react';
import image from '../../../content/front/image-4.png';

import {
    btn1_view5,
    o1_view5,
    o2_view5,
    o3_view5, o4_view5, o5_view5, o6_view5, p1_view1,
    p1_view4,
    p2_view4, t1_view1,
    t1_view4,
    t1_view5
}  from "../../../content/front/const/Text";

export default (props) => {
    return (
        <div>

            <h2 style={{textAlign: 'center'}}>{t1_view5}</h2>

            <div className="View_container">

                <div style={{flex: 1.2, marginTop: '2rem'}}>
                    <div className="View_textContainer">

                        <div>
                            {
                                [o1_view5, o2_view5, o3_view5, o4_view5, o5_view5, o6_view5].map(txt => <div
                                    style={{display: 'flex'}}>
                                    <div className="View_listDecor">

                                    </div>
                                    <p className="View_pList">
                                        {txt}
                                    </p>
                                </div>)
                            }
                        </div>

                        <div style={{textAlign: 'left'}}>
                            <button className="MyButton">
                                {btn1_view5}
                            </button>
                        </div>
                    </div>
                </div>

                <div className="View_imgContainer">
                    <img className="View_img" src={image}/>
                </div>

            </div>
        </div>
    )
};

