import React from 'react';
import {p1_view1, t1_view1} from "../../../content/front/const/Text";
import image from "../../../content/front/image-1.png";

export default (props) => {

    return (
        <div>
            <div className="View_container">

                <div style={{flex: 1.2, marginTop: '2rem'}}>
                    <div className="View_textContainer">
                        <h2>{t1_view1}</h2>

                        <p className="View_p">
                            {p1_view1}
                        </p>

                        <div>
                            <button className="MyButton">
                                ثبت نام
                            </button>
                        </div>
                    </div>
                </div>

                <div className="View_imgContainer">
                    <img className="View_img" src={image}/>
                </div>

            </div>
        </div>
    )
};
