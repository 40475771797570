class UiSetting {
  static serverName = "fintrac";

  static Setting = {
    Mapsun: {
      mapsunSite_Address: "https://mapsunapp.ir/",
      webService_baseAddress: "https://api.mapsunapp.ir/",
      title: "مدیریت پروژه مپسان",
      logo: "Mapsun",
      language: "fa",
      DefaultPageDirection: "rtl",
      textAlign: "right",
    },
    MapsunTCI2: {
      mapsunSite_Address: "https://mapsun2.tci.ir/",
      webService_baseAddress: "https://mapsun2-api.tci.ir/",
      title: "مدیریت پروژه مپسان",
      logo: "Mapsun",
      language: "fa",
      DefaultPageDirection: "rtl",
      textAlign: "right",
    },
    MapsunTCI: {
      mapsunSite_Address: "https://mapsun.tci.ir/",
      webService_baseAddress: "https://mapsun-api.tci.ir/",
      title: "مدیریت پروژه مپسان",
      logo: "Mapsun",
      language: "fa",
      DefaultPageDirection: "rtl",
      textAlign: "right",
    },
    NezaratTCI: {
      mapsunSite_Address: "http://nezarat.tci.ir/",
      webService_baseAddress: "http://nezarat-api.tci.ir/",
      title: "مدیریت پروژه مپسان",
      logo: "Mapsun",
      language: "fa",
      DefaultPageDirection: "rtl",
      textAlign: "right",
    },
    Hirad: {
      mapsunSite_Address: "https://hiradpmo.ir/",
      webService_baseAddress: "https://hiradpmo.ir/",
      title: "مدیریت پروژه مپسان",
      logo: "Mapsun",
      language: "fa",
      DefaultPageDirection: "rtl",
      textAlign: "right",
    },
    fintrac: {
      mapsunSite_Address: "https://amstrategiesgroup.ca/",
      webService_baseAddress: "https://api.amstrategiesgroup.ca/",
      title: "AM Strategies Group",
      logo: "fintrac",
      language: "en",
      DefaultPageDirection: "ltr",
      textAlign: "left",
    },
  };

  static GetSetting(settingName) {
    return this.Setting[this.serverName][settingName];
  }
}

export default UiSetting;
