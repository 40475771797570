import { toast, ToastContainer } from "react-toastify";
import { createBrowserHistory } from "history";
import WebService from "./WebService";
import FieldType from "./class/enums/FieldType";
import FieldInfo from "./class/FieldInfo";
import Utils from "./Utils";
import React from "react";
import SystemClass_Core from "./SystemClass_Core";
import moment from "moment-jalaali";
import DialogReportDesigner from "./components/Dialog/DialogReportDesigner";
import UiSetting from "./UiSetting";

class SystemClass extends SystemClass_Core {
  //------------------------------------------------
  //region menu
  //------------------------------------------------
  static MenuComponent;

  static menuUpdate = () => {
    SystemClass.MenuComponent.update();
  };

  //------------------------------------------------
  //endregion menu
  //------------------------------------------------

  //------------------------------------------------
  //region userInfo
  //------------------------------------------------

  static menuItem_Array;
  static MainMenuData;
  static lastLoginName = "";

  static checkFirstTimeLogin() {
    let lastLogin;
    let lastLoginExpire;

    const userInfo = WebService.getUserInfo();
    if (userInfo.login) return new Promise((resolve) => resolve(true));

    try {
      lastLogin = JSON.parse(window.localStorage._lastLogin);
      lastLoginExpire = window.localStorage._lastLoginExpire;
    } catch (e) {}

    //isBlock
    if (
      lastLogin &&
      lastLoginExpire &&
      moment().diff(moment(lastLoginExpire, "jYYYY/jMM/jDD HH:mm:ss")) <= 0
    ) {
      return new WebService(
        WebService.URL.webService_Login,
        {},
        {},
        lastLogin
      ).then((json) => {
        if (json.successMsg) {
          return true;
        }
      });
    }

    return new Promise((resolve) => resolve());
  }

  static logOut() {
    window.sessionStorage._userInfo = "";
    window.sessionStorage._userInfoExpire = "";

    window.localStorage._lastLogin = "";
    window.localStorage._lastLoginExpire = "";
  }

  static setLastOtp(loginName, otp) {
    return (window.localStorage["_otp_" + loginName] = otp);
  }

  static getLastUserImage(loginName) {
    if (!loginName) {
      loginName = WebService.getUserInfo();
      loginName = loginName.login && loginName.login.loginName;
    }
    return window.localStorage["_userImage_" + loginName];
  }

  static setLastUserImage(loginName, documentCid_UserImage) {
    SystemClass.MenuComponent && SystemClass.MenuComponent.updateImage();

    return (window.localStorage[
      "_userImage_" + loginName
    ] = documentCid_UserImage);
  }

  static setLastPemKey(loginName, pemKey) {
    return (window.localStorage["_pemKey_" + loginName] = pemKey);
  }

  static setLastLogin(loginName, loginUserInfo) {
    return (window.localStorage["_login_" + loginName] = loginUserInfo);
  }

  static getLastLogin(loginName) {
    return window.localStorage["_login_" + loginName];
  }

  static getLastOtp(loginName) {
    return window.localStorage["_otp_" + loginName];
  }

  static getLastPemKey(loginName) {
    return window.localStorage["_pemKey_" + loginName];
  }

  //------------------------------------------------
  //endregion
  //------------------------------------------------

  //------------------------------------------------
  //region loading
  //------------------------------------------------
  static loading;
  static loadingQueue = [];
  static setLoading = (loading) => {
    let resolve = () => {};
    const promise = new Promise((r) => (resolve = r));

    if (SystemClass.loading && loading) {
      SystemClass.loadingQueue.push({
        promise,
        resolve,
      });
      return promise;
    }

    if (SystemClass.loading == loading) return;

    const lastPromise = SystemClass.loadingQueue.shift();
    if (!loading && lastPromise) {
      lastPromise.resolve();
      return lastPromise.promise;
    }

    SystemClass.loading = loading;
    const progress = document.getElementById("MainMenuProgress");
    const mask = document.getElementById("maskDisable");
    const DialogContainer = document.getElementById("DialogContainer");
    const Form__container = document.querySelector(".Form__container");
    const anyDialogOpen = SystemClass.anyDialogOpen();

    requestAnimationFrame(() => {
      const func = loading ? "add" : "remove";
      loading && mask && mask.focus();
      progress && progress.classList[func]("MainMenu__Progress--show");
      mask && mask.classList[func]("maskDisable--show");
      mask &&
        mask.classList[anyDialogOpen ? "add" : "remove"]("maskDisable--full");

      DialogContainer && DialogContainer.classList[func]("dialog--loading");

      // Form__containers.forEach(node => node.classList[func]('Form__container--disable'))
      Form__container &&
        Form__container.classList[func]("Form__container--disable");
    });

    resolve();
    return promise;
  };
  //------------------------------------------------
  //endregion loading
  //------------------------------------------------

  //------------------------------------------------
  //region Message
  //------------------------------------------------

  static _getMessage(msg) {
    msg = msg || "";
    return msg.replace(/!@#/g, "\n").replace(/\(\(.*?\)\)/g, "");
  }

  /***
   *
   * @param {String} msg massage to show
   */
  static showMsg(msg, closeTime) {
    const message = SystemClass._getMessage(msg);
    toast(message, {
      toastId: "_globalToastId",
      autoClose: closeTime || 3500,
    });
  }

  static showWarningMsg(msg) {
    const message = SystemClass._getMessage(msg);

    toast.warn(message, {
      toastId: "_globalWarningToastId",
      className: "toast__warning",
    });
  }

  /***
   *
   * @param {String} msg massage to show
   */
  static showErrorMsg(msg) {
    let message = SystemClass._getMessage(msg);

    message = <span>{message}</span>;

    toast.error(message, {
      toastId: "_globalErrorToastId",
    });
  }

  //------------------------------------------------
  //endregion Message
  //------------------------------------------------

  //------------------------------------------------
  //region web routing
  //------------------------------------------------
  static browserHistory = createBrowserHistory();
  static getBrowserHistory = () => {
    return SystemClass.browserHistory;
  };

  static pushLink = (link, param) => {
    return SystemClass.browserHistory.push(link.replace(/\/\//g, "/"), param);
  };

  static setFormParam = (formName, params) => {
    window.localStorage["Form__" + formName] = JSON.stringify(params);
  };
  static getFormParam = (formName) => {
    return JSON.parse(window.localStorage["Form__" + formName] || "{}");
  };
  static openForm = (formName, params) => {
    SystemClass.setFormParam(formName, params);
    return SystemClass.pushLink("/form/" + formName, { reload: true });
  };
  static openFrame = (frameUrl) => {
    return SystemClass.pushLink("/frame/" + frameUrl);
  };

  static handleUnauthorizeError(error) {
    SystemClass.DialogComponent &&
      SystemClass.DialogComponent.cancelAllDialogs();

    //!redirect to Auth pages if not in there
    //if(SystemClass.browserHistory.location.pathname.startsWith('/auth')){
    SystemClass.pushLink("/auth/login");
    //}

    SystemClass.showErrorMsg(
      UiSetting.GetSetting("language") === "fa"
        ? "ابتدا به سیستم وارد شوید"
        : "Please login to the system first..."
    );
  }

  //------------------------------------------------
  //endregion web routing
  //------------------------------------------------

  //------------------------------------------------
  //region ConnectionStatus component
  //------------------------------------------------
  static ConnectionStatusComponent;
  static ConnectionStatusSetOfflineMode = (mode) => {
    SystemClass.ConnectionStatusComponent.setOfflineMode(mode);
  };
  //------------------------------------------------
  //endregion ConnectionStatus component
  //------------------------------------------------

  //------------------------------------------------
  //region Dialog
  //------------------------------------------------
  static DialogComponent;

  static openDialog(formId, formParams, formFieldInfo, closeDialogCallback) {
    SystemClass.DialogComponent.openDialog(
      formId,
      formParams,
      formFieldInfo,
      closeDialogCallback
    );
  }

  static cancelDialog(formId, formParams) {
    SystemClass.DialogComponent.cancelDialog(formId, formParams);
  }

  static anyDialogOpen() {
    return (
      SystemClass.DialogComponent && SystemClass.DialogComponent.anyDialogOpen()
    );
  }

  //------------------------------------------------
  //endregion Dialog
  //------------------------------------------------

  //------------------------------------------------
  //region Dialog Report
  //------------------------------------------------
  static DialogReportComponent;

  static openDialog(formId, formParams, formFieldInfo, closeDialogCallback) {
    SystemClass.DialogComponent.openDialog(
      formId,
      formParams,
      formFieldInfo,
      closeDialogCallback
    );
  }

  static cancelDialog(formId, formParams) {
    SystemClass.DialogComponent.cancelDialog(formId, formParams);
  }

  static anyDialogOpen() {
    return (
      SystemClass.DialogComponent && SystemClass.DialogComponent.anyDialogOpen()
    );
  }

  //------------------------------------------------
  //endregion Dialog
  //------------------------------------------------

  //------------------------------------------------
  //region confirm Dialog
  //------------------------------------------------
  static confirmDialogComponent;

  static showConfirm(msg, canPostpone, fieldCid) {
    return SystemClass.confirmDialogComponent.openDialog(
      msg,
      canPostpone,
      fieldCid
    );
  }

  //------------------------------------------------
  //endregion confirm Dialog
  //------------------------------------------------

  //------------------------------------------------
  //region auth web services
  //------------------------------------------------

  //------------------------------------------------
  //endregion confirm Dialog
  //------------------------------------------------

  //------------------------------------------------
  //region auth web services
  //------------------------------------------------

  static DialogReportDesignerContainer;

  static showReportDesignerDialog(mrtFileUrl, formModel) {
    SystemClass.DialogReportDesignerContainer.showDialog(mrtFileUrl, formModel);
  }

  static DialogReportViewerContainer;

  static showReportViewerDialog(mrtFileUrl, formModel) {
    SystemClass.DialogReportViewerContainer.showDialog(mrtFileUrl, formModel);
  }

  //------------------------------------------------
  //endregion confirm Dialog
  //------------------------------------------------
}

export default SystemClass;
