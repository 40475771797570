import Enum_Base from "./Enum_Base";

/** Enum Class LabelPosition */
class LabelPosition extends Enum_Base {
    /** Enum of LabelPosition
     * @typedef {string} LabelPosition */

    static LabelOnTop = 'LabelOnTop'
    static LabelOnRight = 'LabelOnRight'
}

export default LabelPosition;